.Contact{
    display: flex;
    padding: 20px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* width: 100%; */
    height:500px;
    color: white;
    
}

.contactDiv{
    text-align: center;
    box-sizing: border-box;
    width: 900px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}


@media only screen and (max-width: 500px) {
    .Contact{
        height: auto;gap: 0;
    }
    .contactDiv {
        width: 90%; 
        height:500px;
        margin-top: 150px;
        gap: 0;
        flex-direction: column; /* Stack items vertically */
    }
    .contactDiv{
        width: auto;
        gap: 0;
        height: auto;
        margin: 0;
    }
}