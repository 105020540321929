@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.rules {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.rulesContainer {
    display: flex;
    align-items: start;
    justify-content: space-around;
    text-align: center;
    box-sizing: border-box;
    gap: 30px;
    overflow: hidden;
    padding: 20px 10px;
    color: white;
}

.rules h2 {
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    font-size: 9rem;
    text-align: center;
    color: white;
    font-family: monospace;
    font-weight: 900;
}

.rulesContainer h2 {
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    font-size: 6rem;
}

.ruleSection {
    /* width: 500px;
    height: 900px; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
}


.rules-container {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.imgsectionRules {
    max-width: 50%;
    height: auto;
    padding-left: 20px;
}


.imgsectionRules .rules-container .text {
    margin: 0;
    color: transparent;
    font-weight: 900;
    height: 800px;
    font-size: 7rem;
    /* Adjust font size as needed */
    font-family: sans-serif;
    padding: 10px;
    /* Add padding for better readability */
    text-align: center;
    /* Center text */
    background-clip: text;
    background-image: url(../assets/rule.jpg);
    background-position: center;
    background-size: cover;
    /* Ensure the background image covers the text */
}

.rulebox {
    /* background-color: rgb(255, 255, 255); */
    max-width: 500px;
    border: 1px solid white;
    font-size: 1.2rem;
    color: #fff;
    padding: 20px;
    text-align: justify;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
}

.rulebox span {
    color: red;
    font-size: 1.5rem;
    font-weight: 500;
}

@media only screen and (max-width: 500px) {
    .rules {
        height: auto;
        padding: 20px;
    }
    .rulesContainer {
        height: auto;
    }
    .rulesContainer {
        flex-direction: column;
        /* Stack items vertically */
        align-items: center;
        /* padding: 20px; */
        gap: 0;
        width: 100%;
        height: auto;
        /* Center items horizontally */
    }

    .imgsectionRules{
        width: 100%;
    }

    .imgsectionRules .rules-container .text {
        font-size: 4.7rem;
        height: auto;
        padding: 0;
        width: 100%;
    }


    .ruleSection {
        width: 100%;
        height: auto;
    }

    .rules-container {
        width: 100%;
        padding: 0;
        /* Adjust width to fit smaller screens */
    }

    .rulebox {
        width: 90%;
        /* Adjust width to fit smaller screens */
    }

    .rules h2 {
        font-size: 6rem;
        /* Adjust font size for h2 */
    }

    .rulebox span {
        font-size: 2rem;
        /* Adjust font size for span */
    }
}