@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body {
  width: 100%;
  font-family: "Raleway", sans-serif;
  background-color: #121212;
}


a{
  text-decoration: none;
}