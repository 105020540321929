.events {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  color: white;
}

.events_container {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  margin-bottom: 5%;
  padding: 20px 10px;
}

.events_container h2 {
  font-size: 3.5rem;
  margin-top: 1.5rem;
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
}

.events_cards {
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px; */
  /* width: 90vw; */
  /* height: fit-content; */
  overflow: hidden;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.event_card {
  overflow: hidden;
  position: relative;
  border: 2px solid transparent;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  max-width: 300px;
}

.event_card::before,
.event_card::after {
  content: " ";
  width: 10px;
  height: 10px;
  position: absolute;
  border: 0px solid #fff;
  transition: all 1s;
  z-index: 1;
}

.event_card::before {
  bottom: -1px;
  right: -1px;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
}

.event_card::after {
  top: -1px;
  left: -1px;
  border-top: 5px solid white;
  border-left: 5px solid white;
}

.event_card:hover {
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.event_card:hover::before,
.event_card:hover::after {
  width: 100%;
  height: 100%;
}

.event_card img {
  width: 100%;
  height: 100%;
  z-index: -1;
}





@media screen and (max-width: 768px){

    
.events_container {
    max-width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin: 10% 0;
    padding: 10px;
  }
  
  /* .events_cards { */
    /* grid-template-columns: repeat(2, 1fr);
    gap: 30px; */
  /* } */
  

}


@media screen and (max-width: 450px){

    .events_container h2{
        font-size: 2.5rem;
    }
    .events_container{
        width: 90%;
        gap: 20px;
        justify-content: center;
    }

    /* .events_cards{
        grid-template-columns: minmax(200px, 300px);
    } */
}