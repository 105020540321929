.about{
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;

}

.about_content{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    margin: 5% 0;
    padding: 20px 10px ;
}

.about_content h2{
    font-size: 3.5rem;
    margin-top: 1rem;
    color: transparent;
    -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
}

.about_content p{
    max-width: 80%;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: justify;
}



@media screen and (max-width: 768px) {
    .about_content{
        width: 90%;
        gap: 25px;
        padding: 20px 10px ;
    }

    .about_content p{
        max-width: 90%;
        font-size: 1.4rem;
        line-height: 2rem;
        text-align: justify;
    }
    
    
}


@media screen and (max-width: 450px){

    .about_content h2{
        font-size: 2.5rem;
    }


    .about_content p{
        max-width: 100%;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

}