

.container {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}


video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
}



.home_content{
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    font-size: 2rem;
    z-index: 5;
}

.home_content img{
    width: 40vw;
}
/* 
.home_content h2{
    font-size: 4rem;
    font-weight: bold;
    line-height: 1.5;
} */


.home_content button{
    padding: 15px 20px;
    border: 3px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #42d0f8, #95faff);
    border-radius: 20px;
    background-color: transparent;
}

.home_content button a{
    text-decoration: none;
    font-size: 1.2rem;
    color: #fff;

}




@media screen and (max-width: 768px) {
    /* .home_content h2{
        font-size: 3rem;
    } */

    .home_content img{
        width: 60vw;
    }
    
}


@media screen and (max-width: 450px) {
    /* .home_content h2{
        font-size: 2.5rem;
    } */

    .home_content img{
        width: 80vw;
    }
    
}