nav {
  width: 100%;
  height: 80px;
  padding: 20px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 10;
  transition: all .2s ease-in;
}

.nav_logo {
  max-width: 200px;
  padding: 15px;
}

.nav_logo img{
  width: 100%;
}

.nav_links_ul {
  display: flex;
  gap: 20px;
  list-style: none;
  cursor: pointer;
  position: relative;
}

.cross {
  display: none;
}

.cross img {
  width: 40px;
  position: absolute;
  right: 30px;
  top: 20px;
}

.nav_link {
  text-decoration: none;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  position: relative;
}

.nav_link::before {
  content: "";
  position: absolute;
  bottom: -5px;
  right: 0;
  width: 0;
  height: 3px;
  border-radius: 5px;
  background-color: aqua;
  transition: all 0.3s ease-in-out;
}

.nav_link:hover::before {
  width: 100%;
  left: 0;
}

.nav_menu {
  display: none;
  cursor: pointer;
}

.nav_menu img {
  width: 30px;
  height: 30px;
  z-index: 20;
}


.scroll_navbar{
  background-color: #080808;
    box-shadow: 0 -1px 10px rgba(147, 147, 147, 0.25);
}


@media screen and (max-width: 768px) {
  nav {
    padding: 15px 20px;
  }
  .nav_links_ul {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 50px 0;
    position: absolute;
    right: -100%;
    top: 0;
    transition: all 0.3s ease-in;
    background-color: rgb(3, 1, 36);
    width: 40%;
    height: 50vh;
    opacity: 1;
  }

  .nav_link {
    font-size: 1.5rem;
  }

  .cross {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .show {
    right: 0;
  }

  .nav_links_ul img {
    display: block;
  }

  .nav_menu {
    display: block;
  }
}

@media screen and (max-width: 500px) {

  nav{
    padding: 10px 20px;
  }
  .nav_links_ul {
    width: 50%;
  }

  .nav_logo{
    padding: 0;
  }

  .nav_logo img{
    width: 120px;
  }

  .nav_link {
    font-size: 1.2rem;
  }

  .cross img {
    width: 25px;
  }
}
