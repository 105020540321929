@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.our_event {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.contentOfEvent {
    display: flex;
    flex-direction: column ;
    align-items: center;
    width: 100%;
    text-align: justify;
    box-sizing: border-box;
    width: 900px;
}
.eventImg{
    width: 900px;
}

.eventImg img{
    width: 100%;
}

.contentOfEvent h2 {
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    padding: 10px;
    margin: 0;
    padding: 0;
    font-size: 12rem;
    letter-spacing: 1rem;
    color: white;
}

.contentOfEvent p {
    font-family: "Poppins", sans-serif;
    color: rgb(255, 255, 255);
    padding: 20px;
    font-weight: 200;
    text-align: justify;
    letter-spacing: 0.1rem;
}

@media only screen and (max-width: 500px) {
    .contentOfEvent {
        width: 100%; /* Adjust the width to fit smaller screens */
    }

    .eventImg,
    .eventImg img {
        width: 100%; /* Make the event image responsive */
    }

    .contentOfEvent h2 {
        font-size: 4.5rem; /* Adjust the font size for h2 */
        letter-spacing: 0.5rem; /* Adjust the letter spacing for h2 */
    }
    .contentOfEvent p {
        padding: 20px;
    }
}