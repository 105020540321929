.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -100;
}

.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: -1;
  /* Ensure video stays behind other content */
}

@media only screen and (max-width: 500px) {
  .video-background video {
    display: block;
    height: 100vh; /* Hide the video at this width */
  }
}


.overlay-text {
  position: absolute;
  /* height: 350px; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  flex-direction: column;
  color: #fff;
  /* Adjust text color */
}


.overlay-text h1 {
  margin-top: -30px;
  padding: 40px;
  font-size: 4rem;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: #fff;
}


@media only screen and (max-width: 500px) {
  .overlay-text {
    height: auto;
    padding: 20px;
  }

  .overlay-text h1 {
    font-size: 2.5rem;
    padding: 20px;
  }
}


/* CSS */
.button-73 {
  background-color: transparent;
  border-radius: 40em;
  border: 2px solid #fff;
  /* box-shadow: #ADCFFF 0 -12px 6px inset; */
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: -.24px;
  margin: 0;
  outline: none;
  padding: 1rem 1.3rem;
  quotes: auto;
  text-align: center;
  text-decoration: none;
  transition: all .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-73:hover {
  background-color: #FFC229;
  box-shadow: #FF6314 0 -6px 8px inset;
  color: #fff;
  transform: scale(1.125);
}

.button-73:active {
  transform: scale(1.025);
}

@media (min-width: 768px) {
  .button-73 {
    font-size: 1.5rem;
    padding: .75rem 2rem;
  }
}